import { component } from 'picoapp'
import choozy from 'choozy'
import { remove, add, qs, on, qsa, has, noop } from 'martha'
import EmblaCarousel from 'embla-carousel'

export default component((node, ctx) => {
  let { carousel, slideIndex, box, lightprev, lightnext, lightclose, lightImg, lightCaption } = choozy(node)

  let captions = qsa('.js-caption', node)
  let nextBtns = qsa('.js-next', node)
  let prevBtns = qsa('.js-prev', node)
  let embla = noop()
  let offImg = noop()

  qsa('img', node).forEach((cImg, index) => {
    cImg.setAttribute('data-index', index)
  })

  const lightbox = () => {
    remove(box, 'opacity-0')
    remove(box, 'pointer-events-none')
  }

  const options = {
    loop: true,
    skipSnaps: false,
    align: 'start',
    draggable: false,
  }

  const initEmbla = () => {
    embla = EmblaCarousel(carousel, options)

    if (prevBtns != undefined && nextBtns != undefined) {
      prevBtns.forEach((prev) => {
        prev.addEventListener('click', embla.scrollPrev, false)
      })
      nextBtns.forEach((next) => {
        next.addEventListener('click', embla.scrollNext, false)
      })
    }

    const onSelect = (e) => {
      let curIndex = embla.selectedScrollSnap() + 1

      if (captions) {
        captions.forEach((cpt) => {
          add(cpt, 'hidden')
          remove(qs(`.js-caption[data-index="${curIndex}"]`, node), 'hidden')
        })
      }

      if (slideIndex) {
        slideIndex.innerHTML = curIndex
      }
    }

    embla.clickAllowed()

    embla.on('select', () => onSelect())

    //setup lightbox
    offClose = on(lightclose, 'click', () => {
      add(box, 'opacity-0')
      add(box, 'pointer-events-none')

      setTimeout(() => {
        lightImg.src = ''
      }, 300)
    })

    const onSlideClick = (e) => {
      if (embla.clickAllowed()) {
        activeIndex = e

        lightbox()

        lightImg.src = qs(`.js-lazy[data-index="${e}"]`, node).src
        lightCaption.innerHTML = qs(`.js-lazy[data-index="${e}"]`, node).dataset.caption
      }
    }

    const navLightbox = (dir) => {
      let targetIndex = parseInt(activeIndex) + dir
      let targetImg = qs(`.js-lazy[data-index="${targetIndex}"]`, node)


      if (dir > 0) {
        if (targetImg) {
          lightImg.src = targetImg.src
          lightCaption.innerHTML = targetImg.dataset.caption
          activeIndex = targetIndex
        } else {
          lightImg.src = qs('.js-lazy[data-index="0"]', node).src
          lightCaption.innerHTML = qs('.js-lazy[data-index="0"]', node).dataset.caption
          activeIndex = 0
        }
      } else {
        if (targetImg) {
          lightImg.src = targetImg.src
          lightCaption.innerHTML = targetImg.dataset.caption
          activeIndex = targetIndex
        } else {
          lightImg.src = qs(
            `.js-lazy[data-index="${qsa('.js-lazy', node).length - 1}"]`,
            node,
          ).src
          lightCaption.innerHTML = qs(
            `.js-lazy[data-index="${qsa('.js-lazy', node).length - 1}"]`,
            node,
          ).dataset.caption
          activeIndex = qsa('.js-lazy', node).length - 1
        }
      }
    }

    on(lightprev, 'click', () => {
      navLightbox(-1)
    })

    on(lightnext, 'click', () => {
      navLightbox(1)
    })

    embla.slideNodes().forEach((slideNode, index) => {
      slideNode.addEventListener('click', () => onSlideClick(index), false)
    })

    return () => {
      embla.off()
      offClose()
      offImg()
      if (prevBtns != undefined && nextBtns != undefined) {
        prevBtns.forEach((prev) => {
          prev.removeEventListener('click', embla.scrollPrev, false)
        })
        nextBtns.forEach((next) => {
          next.removeEventListener('click', embla.scrollNext, false)
        })
      }
    }
  }

  if (node.dataset.mobile == 'only') {
    if (document.documentElement.clientWidth < 850) initEmbla()
  } else {
    initEmbla()
  }
})
