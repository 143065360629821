import { component } from 'picoapp'
import { noop, on, remove, has } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let offLoad = noop
  let offEnd = noop

  ctx.on('enter:completed', () => {
    let refs = choozy(node)

    offLoad = on(node, 'load', () => {
      offLoad()
      offLoad = noop

      if (refs?.lqip) {
        offEnd = on(node, 'transitionend', () => {
          offEnd()
          offEnd = noop
          refs.lqip.remove()
        })
      }

      remove(node, 'pixelate')
    })

    node.src = node.dataset.src
    node.removeAttribute('data-src')
  })

  return () => {
    offLoad()
    offEnd()
  }
})