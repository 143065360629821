import { component } from 'picoapp'
import choozy from 'choozy'
import { on, qs, add, remove } from 'martha'
import { subscribe } from 'klaviyo-subscribe'

export default component((node, ctx) => {
  let { form, success, submit, email } = choozy(node)

  on(form, 'submit', (e) => {
    e.preventDefault()

    let listId = 'T3D4EH'
    let address = email.value

    subscribe(listId, address, {}).then((response) => {
      remove(success, 'hidden')
      add(form, 'hidden')
    })
  })
})
