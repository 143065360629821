import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'
import Fuse from 'fuse.js'

export default component((node, ctx) => {
  let { result } = choozy(node)

  const projectOptions = {
    isCaseSensitive: false,
    findAllMatches: true,
    threshold: 0.2,
    distance: 200,
    keys: [
      'title',
      'overview',
      'slug',
      'types',
      'phases',
      'categories',
      'sizes',
      'description',
      'credits',
      'location'
    ],
  }

  const profileOptions = {
    isCaseSensitive: false,
    findAllMatches: true,
    threshold: 0.2,
    distance: 10,
    keys: ['nameFirst', 'nameLast', 'slug', 'role', 'roleSub', 'description'],
  }

  function getJsonFromUrl(url) {
    if (!url) url = location.search
    let query = url.substr(1)
    let result = {}
    query.split('&').forEach(function (part) {
      let item = part.split('=')
      result[item[0]] = decodeURIComponent(item[1])
    })
    return result.query
  }

  const searchQuery = getJsonFromUrl()

  if (searchQuery) {
    let targetSlot

    //search projects and articles
    let projectContent = JSON.parse(node.dataset.projects)
    const fuseProject = new Fuse(projectContent, projectOptions)

    const projectResults = fuseProject.search(searchQuery)

    projectResults.forEach((item) => {
      let type = item.item.itemType

      if (type == 'article') {
        targetSlot = qs('.js-newsResults')
        remove(qs('.js-news'), 'hidden')
      } else {
        targetSlot = qs('.js-projectResults')
        remove(qs('.js-projects'), 'hidden')
      }

      targetSlot.insertAdjacentHTML(
        'beforeend',
        `<a class="col-span-12 m:col-span-4 js-searchItem" href="/${
          type == 'project' ? 'projects' : 'articles'
        }/${item.item.slug}">
            <div class="w-full pb-[100%] relative rounded-10 overflow-hidden bg-mgrey">
                ${
                  item.item.image
                    ? `<img class="w-full h-full object-cover absolute top-0 left-0" src="${item.item.image}" alt="search result"/>`
                    : ''
                }
            </div>
            <div class="w-full flex justify-between items-center mt-10">
                <span class="text-14 uppercase">${item.item.title}</span>
            </div>
        </a>`,
      )
    })

    //search profiles
    let profileContent = JSON.parse(node.dataset.profiles)
    const fuseProfile = new Fuse(profileContent, profileOptions)

    const profileResults = fuseProfile.search(searchQuery)

    profileResults.forEach((item) => {
      targetSlot = qs('.js-profileResults')
      remove(qs('.js-profiles'), 'hidden')

      targetSlot.insertAdjacentHTML(
        'beforeend',
        `<a class="col-span-12 m:col-span-4 js-searchItem" href="/team/${
          item.item.slug
        }">
            <div class="w-full pb-[100%] relative rounded-10 overflow-hidden bg-mgrey">
                ${
                  item.item.image
                    ? `<img class="w-full h-full object-cover absolute top-0 left-0" src="${item.item.image}" alt="search result"/>`
                    : ''
                }
            </div>
            <div class="w-full flex justify-between items-center mt-10">
                <span class="text-14 uppercase">${item.item.name}</span>
            </div>
        </a>`,
      )
    })

    let projectsFound = qsa('.js-searchItem:not(.hidden)')

    result.innerHTML = `${
      projectsFound.length == 1
        ? '1 Result for'
        : `${projectsFound.length} Results for`
    } “${searchQuery}”`
  } else {
    result.innerHTML = '0 Search Results'
  }
})
