import { picoapp } from 'picoapp'
import { size, qs } from 'martha'

import lazy from './components/lazy'
import lazyVideo from './components/lazyVideo'
import scroll from './components/scroll'
import sticky from './components/sticky'
import carousel from './components/carousel'
import modelviewer from './components/modelviewer'
import modal from './components/modal'
import filter from './components/filter'
import search from './components/search'
import searchResults from './components/searchResults'
import map from './components/map'
import drawerSlider from './components/drawerSlider'
import navigateFilter from './components/navigateFilter'
import form from './components/form'
import counter from './components/counter'
import menu from './components/menu'
import reveal from './components/reveal'
import loader from './components/loader'
import nav from './components/nav'
import signup from './components/signup'

const components = {
  lazy,
  lazyVideo,
  scroll,
  sticky,
  carousel,
  modelviewer,
  modal,
  filter,
  search,
  searchResults,
  map,
  drawerSlider,
  navigateFilter,
  form,
  counter,
  menu,
  reveal,
  loader,
  nav,
  signup
}

const sizes = size()
const state = {
  ...sizes,
  mx: sizes.ww / 2,
  my: sizes.wh / 2,
  dom: {
    html: document.documentElement,
    body: document.body,
    scrollProxy: qs('.js-scroll-proxy'),
  },
  fonts: [
    // { family: 'GT Walsheim' },
    // { family: 'GT Walsheim', options: { weight: 300 } },
    // { family: 'GT Walsheim', options: { weight: 300, style: 'italic' } },
  ],
}

export default picoapp(components, state)
