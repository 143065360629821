import { component } from 'picoapp'
import { on, remove, add, qsa, toggle, qs } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { filter, zero, filters, toggleFilter, plus, projects} = choozy(node)
  let params
  let url

  //Toggle active filter button
  remove(document.body, 'overflow-hidden')
  const offToggle = on(toggleFilter, 'click', () => {
    toggle(filters, 'hidden')
    toggle(plus, 'rotate-45')
    toggle(qs('.js-toggleFilter.is-zone'), 'pointer-events-none')
    toggle(qs('.js-toggleFilter.is-zone'), 'opacity-0')
    toggle(document.body, 'overflow-hidden')
  })

  const updateParams = on(filter, 'click', (e) => {
    let filterMatch
    let selectedType = e.target.dataset.type
    let selectedFilter = e.target.dataset.filter

    url = new URL(window.location)
    params = url.searchParams

    filterMatch = params.getAll(selectedType)

    if (filterMatch[0]?.split(',').some((value) => value === selectedFilter)) {
      //toggle active state
      remove(e.target, 'active')
      add(e.target, 'inactive')

      if(filterMatch[0]?.split(',').length > 1){
        params.set(selectedType, filterMatch[0].split(',').filter(f => f != selectedFilter).join());
      } else{
        params.delete(selectedType)
      }
    } else {
      //toggle active state
      add(e.target, 'active')
      remove(e.target, 'inactive')

      if(filterMatch[0]?.split(',').length){
        params.set(selectedType, `${filterMatch[0]},${selectedFilter}`);
      } else{
        params.set(selectedType, selectedFilter);
      }
    }

    //Update url with new params
    let new_url = url.toString()
    if (history.pushState) {
      window.history.pushState({ path: new_url }, '', new_url)
    }

    filterProjects()
  })

  const filterProjects = () => {
    let filterOptions = ['size', 'type', 'phase']
    let activeFilters = []

    url = new URL(window.location)
    params = url.searchParams

    filterOptions.forEach((option) => {
      let optionFilters = params.getAll(option)[0]
      if (optionFilters) activeFilters.push({option, optionFilters})
    })

    if(activeFilters.length){
      add(projects, 'is-filtered')
    } else{
      remove(projects, 'is-filtered')
    }

    // let filterCategory = activeFilters.find((f) => f.option == 'category')
    // let categories = filterCategory?.optionFilters

    qsa('.js-filterItem').forEach(item => {
      let matched = true
      activeFilters.forEach(option => {
        let matchedFilter = option.optionFilters.split(',').some(f => item.dataset[option.option]?.split(' ').includes(f));

        if (!matchedFilter) {
          matched = false
          return false
        }
      })

      if (!matched){
        add(item, 'hidden')
      } else{
        remove(item, 'hidden')
      }      
    })

    if (qsa('.js-filterItem:not(.hidden)').length < 1){
      remove(zero, 'hidden')  
    } else{
      add(zero, 'hidden')  
    }
  }

  //filter on load
  // filterProjects()

  return () => {
    updateParams()
    offToggle()
  }
})
