import { component } from 'picoapp'
import { gsap } from 'gsap'
import { add, remove, qs} from 'martha'

export default component((node, ctx) => {
  const loadContent = () => {
    const tl = gsap.timeline()

    gsap.set(qs('.js-homeContent'), { y: '100vh' })
    gsap.set(qs('.js-heroVideo'), { scale: 0.5 })

    const introContent = () => {
      window.scrollTo(0, 0)
      tl.to('.js-homeContent', { y: 0, duration: 1.5, ease: 'cubic.inOut' })
        .to(
          '.js-loaderContent',
          { y: '-100%', duration: 1.5, ease: 'cubic.inOut' },
          '-=1.5',
        )
        .to(
          '.js-heroVideo',
          {
            scale: 1,
            duration: 1.35,
            ease: 'cubic.inOut',
            onComplete: () => {
              remove(document.body, 'lock')
              sessionStorage.setItem('loaded', true)
            },
          },
          '-=.5',
        )
    }

    setTimeout(() => {
      tl.to('.js-o', { x: 0, duration: 1, ease: 'expo.out' })
        .to('.js-a', { x: 0, opacity: 0, duration: 1, ease: 'expo.out' }, 0)
        .to('.js-d', { opacity: 0, duration: 1, ease: 'expo.out' }, 0)
        .set('.js-loaderStick', { opacity: 1 }, '-=.5')
        .to(
          '.js-loaderIcon',
          {
            y: '-50%',
            duration: 0.5,
            ease: 'back.out(1.7)',
          },
          '-=.5',
        )
        .fromTo(
          '.js-loaderSpan',
          { opacity: 0, y: 30 },
          {
            opacity: 1,
            y: 0,
            stagger: 0.15,
            duration: 1,
            ease: 'expo.out',
            onComplete: () => {
              setTimeout(() => {
                introContent()
              }, 500)
            },
          },
          '-=.35',
        )
    }, 1000)
  }

  if (sessionStorage.getItem('loaded') != 'true') {
    add(document.body, 'lock')
    loadContent()
  } else {
    node.remove()
  }

  // loadContent()
})
