import { component } from 'picoapp'
import gsap from 'gsap'
import { add, remove, on, qs, qsa, noop } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { map } = choozy(node)
  let offEnter = noop()
  let offLeave = noop()
  const mapWidth = map.clientWidth
  const mapHeight = mapWidth * 0.77444286

  JSON.parse(node.dataset.json).forEach((person) => {
    let name = person.name
    let lat = parseInt(person.latitude)
    let lon = parseInt(person.longitude)
    const { x, y } = latLonToOffsets(lat, lon, mapWidth, mapHeight)
    renderPointer(x, y, name)
  })

  /**
   * @param {number} latitude in degrees
   * @param {number} longitude in degrees
   * @param {number} mapWidth in pixels
   * @param {number} mapHeight in pixels
   */
  function latLonToOffsets(latitude, longitude, mapWidth, mapHeight) {
    const radius = mapWidth / (2 * Math.PI)
    const FE = 180 // false easting

    const lonRad = degreesToRadians(longitude + FE)
    const x = lonRad * radius

    const latRad = degreesToRadians(latitude)
    const verticalOffsetFromEquator =
      radius * Math.log(Math.tan(Math.PI / 4 + latRad / 2))
    const y = mapHeight / 2 - verticalOffsetFromEquator

    return { x, y }
  }

  /**
   * @param {number} degrees
   */
  function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180
  }

  function renderPointer(x, y, name) {
    if (x && y) {
      const pointer = document.createElement('span')
      pointer.classList.add('icon-map')
      pointer.style.left = `${(x / mapWidth) * 100}%`
      pointer.style.top = `${(y / mapHeight) * 100}%`
      pointer.style.transform = 'translateY(-50%) translateX(-50%)'
      pointer.dataset.name = name
      node.appendChild(pointer)
    }
  }

  const offMove = on(document, 'mousemove', (e) => {
    //   console.log(e);
    qs('.js-tooltip').style.left = `${e.pageX}px`
    qs('.js-tooltip').style.top = `${e.pageY}px`
  })

  qsa('.icon-map').forEach((icon) => {
    //TO DO remove listeners
    offEnter = on(icon, 'mouseenter', () => {
      remove(qs('.js-tooltip'), 'opacity-0')
      qs('.js-name').innerHTML = icon.dataset.name
    })

    offLeave = on(icon, 'mouseout', () => {
      add(qs('.js-tooltip'), 'opacity-0')
      qs('.js-name').innerHTML = icon.dataset.name
    })
  })

  return () => {
    offMove()
    offEnter()
    offLeave()
  }
})
