import { component } from 'picoapp'
import choozy from 'choozy'
import { on, qs, qsa, noop, add, remove, has } from 'martha'
import { ScrollToPlugin, ScrollTrigger, gsap, Expo } from 'gsap/all'

export default component((node, ctx) => {
  let { toggle } = choozy(node)
  let isAnimating = false

  gsap.registerPlugin(ScrollToPlugin)

  const offToggle = on(toggle, 'click', (e) => {
    if (has(e.target, 'active') || isAnimating) return

    isAnimating = true
    let selectedType = e.target.dataset.type

    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: `#project-${selectedType}`,
        offsetY: 80,
      },
      ease: 'expo.out',
      onComplete: () => {
        isAnimating = false
      },
    })

    remove(qs('.btn-toggle.active'), 'active')
    add(e.target, 'active')
  })

  return () => {
    offToggle()
  }
})
