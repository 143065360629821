import { component } from 'picoapp'
import gsap from 'gsap'
import { add, remove, on, qs, qsa, rect, toggle } from 'martha'
import choozy from 'choozy'
import inview from '../lib/inview'

export default component((node, ctx) => {
  let { title } = choozy(node)
  const tl = gsap.timeline()

  const animateMinimal = () => {
    add(node, 'is-visible')
  }

  const animateItems = () => {
    tl.fromTo(
      qsa('.js-animateItem', node),
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, stagger: 0.15, duration: .7, ease: 'cubic.out' },
    )
  }

  let off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
      off()
      if (node.dataset.reveal == 'items') {
        animateItems()
      } else {
        animateMinimal()
      }
    }
  })

  return () => {
    off()
  }
})
