import { qs, remove, add } from 'martha'


export default function checkpage() {
  if (window.location.pathname === '/' || (window.location.pathname.includes('/projects/') && (window.location.pathname != '/projects/' && !window.location.pathname.includes('/projects/categories')))) {
    add(qs('header'), 'header-invert')
  } else{
    remove(qs('header'), 'header-invert')
  }

  if(window.location.pathname.includes('/projects')){
    add(qs('.header-icon'), 'is-large')
  } else{
    remove(qs('.header-icon'), 'is-large')
  }
}
