import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { qsa, on, add, remove, qs } from 'martha'
import { component } from 'picoapp'
import inview from '../lib/inview'

import {
  EffectComposer,
  EffectPass,
  HueSaturationEffect,
  RenderPass,
} from 'postprocessing'

export default component((node, ctx) => {
  let zoomToggle = qsa('.js-zoomToggle')[0]
  let camera, scene, renderer
  let mesh
  let composer

  const ds = 120
  const dm = 100
  const dl = 90

  let isMobile = document.documentElement.clientWidth < 850

  //grab asset path
  let modelAsset = node.dataset.asset
  //remove asset path from element
  node.removeAttribute('data-asset')

  let containerWidth = node.offsetWidth
  let containerHeight = isMobile ? containerWidth : containerWidth / 2

  const configCamera = () => {

    ww = document.documentElement.clientWidth
    let aspect = containerWidth / containerHeight
    let d
    if (ww > 850) {
      d = dl
    } else if (ww > 540) {
      d = dm
    } else {
      d = ds
    }

    //create perspective camera
    camera = new THREE.PerspectiveCamera( 45, aspect, 1, 1000 );

    camera.updateProjectionMatrix()
    renderer.setSize(containerWidth, containerHeight)

    //position camera
    camera.rotation.order = 'YXZ'
    camera.rotation.y = -Math.PI / 1.5
    camera.rotation.x = Math.atan(-1 / Math.sqrt(2))

    // Position camera
    camera.position.set(150, 150, 150)

    controls = new OrbitControls(camera, renderer.domElement)
    controls.enableRotate = true
    controls.enableDamping = true
    controls.dampingFactor = 0.05
    controls.enableZoom = false

    camera.lookAt(0, 0, 0)

    renderer.setSize(containerWidth, containerHeight)

    controls.update()
  }

  function init() {
    // /////////////////////////////////////////////////////////////////////////

    // initial setup

    scene = new THREE.Scene()

    // initialize renderer
    renderer = new THREE.WebGLRenderer({
      antialias: false,
      stencil: false,
      depth: false,
      alpha: true,
      powerPreference: 'high-performance',
    })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.VSMShadowMap // default THREE.PCFShadowMap
    renderer.setClearColor(0xffffff, 0)
    renderer.physicallyCorrectLights = true
    renderer.toneMapping = THREE.LinearToneMapping

    node.appendChild(renderer.domElement)

    configCamera()

    // /////////////////////////////////////////////////////////////////////////

    // create lighting
    const mapSize = 4096

    var hemiLight = new THREE.HemisphereLight(0xf5f7ff, 0xffffff, 1.2)
    scene.add(hemiLight)

    const dirLight = new THREE.DirectionalLight(0xffffff, 3)
    dirLight.position.set(150, 100, 50)
    let d = 1000
    let r = 2
    dirLight.castShadow = true
    dirLight.shadow.radius = r
    dirLight.shadow.camera.top = dirLight.shadow.camera.right = d
    dirLight.shadow.camera.bottom = dirLight.shadow.camera.left = -d
    dirLight.shadow.camera.near = 50
    dirLight.shadow.camera.far = 1000
    dirLight.shadow.mapSize.width = mapSize
    dirLight.shadow.mapSize.height = mapSize
    dirLight.shadow.camera.visible = true
    // dirLight.shadow.bias = .0002

    scene.add(dirLight)

    // /////////////////////////////////////////////////////////////////////////

    // Instantiate a loader
    const loader = new GLTFLoader()

    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderConfig({ type: 'js' })
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
    loader.setDRACOLoader(dracoLoader)

    //load model
    loader.load(
      modelAsset,
      function (gltf) {
        mesh = gltf.scene
        mesh.traverse(function (model) {
          if (model.isMesh) {
            model.castShadow = true
            model.receiveShadow = true
            mesh.name = 'oda-model'

            qs('.js-modelloader')?.remove()

            if (model.material.map) {
              model.material.map.anisotropy = 16
            }
          }
        })
        scene.add(mesh)
      },
      // called while loading is progressing
      function (xhr) {
        if (xhr.loaded == xhr.total) {
        }
      },
      // called when loading has errors
      function (error) {
        console.log('An error occured while loading the model')
      },
    )

    // /////////////////////////////////////////////////////////////////////////

    window.addEventListener('resize', onWindowResize, false)
  }

  function onWindowResize() {
    isMobile = document.documentElement.clientWidth < 850
    containerWidth = node.offsetWidth
    containerHeight = containerWidth / 2

    configCamera()
  }

  function render() {
    renderer.render(scene, camera)
    if (composer) composer.render()

    window.requestAnimationFrame(render)
  }

  let off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
      off()

      //initialize scene on desktop
      if (document.documentElement.clientWidth > 850) {
        init()

        //render scene
        render()

        composer = new EffectComposer(renderer)
        if (composer) {
          composer.addPass(new RenderPass(scene, camera))
          composer.addPass(new EffectPass(camera, new HueSaturationEffect({saturation: .5})))
        }
      }
    }
  })

  const offZoom = on(zoomToggle, 'click', () => {
    // to disable zoom
    controls.enableZoom = !controls.enableZoom

    zoomToggle.innerHTML = controls.enableZoom ? 'Disable Zoom' : 'Enable Zoom'
  })

  const offDown = on(node, 'mousedown', () => {
    modelClicked = true

    add(node, 'is-dragging')
  })

  const offUp = on(node, 'mouseup', () => {
    modelClicked = false

    remove(node, 'is-dragging')
  })

  return () => {
    off()
    offZoom()
    offUp()
    offDown()
  }
})
