import { on, noop, qs } from 'martha'
import { component } from 'picoapp'
import inview from '../lib/inview'
import choozy from 'choozy'

export default component((node, ctx) => {
  let {play, vimeo, cover, lazy} = choozy(node)
  let isPlaying
  let offPlay = noop

  if(node.dataset.autoplay == 'false'){
    offPlay = on(play, 'click', () => {
      vimeo.play()
      vimeo.setAttribute('controls', 'controls')
      cover.remove()
    })
  } else{
    on(vimeo, 'play', () => {
      lazy.remove()
    })
  }

  const off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
      if (node.dataset.autoplay != 'false') {
        if (isPlaying) return
        vimeo.play()
        isPlaying = true
      }
    } else {
      if (!isPlaying) return
      vimeo.pause()
      isPlaying = false
    }
  })

  return () => {
    off()
    offPlay()
  }
})
