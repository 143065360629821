import { component } from 'picoapp'
import choozy from 'choozy'
import { on, qsa, add, remove } from 'martha'

export default component((node, ctx) => {
  let { form, success, optinradio, optin, checkbox, submit, textarea } =
    choozy(node)
  let inputs = qsa('input', node)
  let selects = qsa('select', node)
  let emptyValues = true
  const formId = form.dataset.id

  const checkForm = () => {
    inputs?.some((input) => {
      if (input.value.length > 0) {
        selects?.some((select) => {
          if (select.value != 'Choose Topic') {
            emptyValues = false
          }
        })
      } else {
        emptyValues = true
      }
    })
  }

  inputs.forEach((input) => {
    on(input, 'input', () => {
      checkForm()
      if (emptyValues) {
        submit.disabled = true
      } else {
        submit.removeAttribute('disabled')
      }
    })
  })

  if (textarea) {
    on(textarea, 'input', () => {
      checkForm()
      if (emptyValues) {
        submit.disabled = true
      } else {
        submit.removeAttribute('disabled')
      }
    })
  }

  if (selects) {
    selects.forEach((select) => {
      on(select, 'change', () => {
        checkForm()
        if (emptyValues) {
          submit.disabled = true
        } else {
          submit.removeAttribute('disabled')
        }
      })
    })
  }

  const offForm = on(form, 'submit', (e) => {
    e.preventDefault()

    const data = new FormData(e.target)
    const value = Object.fromEntries(data.entries())

    fetch(`https://submit-form.com/${formId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(value),
    })
      .then(function (response) {
        add(form, 'hidden')
        remove(success, 'hidden')
      })
      .catch(function (error) {
        console.error(error)
      })
  })

  return () => {
    offForm()
  }
})
