import { component } from 'picoapp'
import gsap from 'gsap'
import { add, remove, on, qs, qsa, rect, toggle } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let curScroll

  const offScroll = on(window, 'scroll', () => {
    curScroll = window.pageYOffset || document.documentElement.scrollTop

    if (window.location.pathname.includes('/projects/') && document.documentElement.clientWidth < 850){
      remove(qs('header'), 'header-invert')
      return
    }

    if (curScroll > qs('.js-hero').offsetHeight - qs('header').offsetHeight) {
      remove(qs('header'), 'header-invert')
    } else {
      add(qs('header'), 'header-invert')
    }
  })

  return () => {
    offScroll()
  }
})
