import { component } from 'picoapp'
import { on } from 'martha'

export default component((node, ctx) => {
  const offFilter = on(node, 'click', () => {
      window.location.href = `/${node.dataset.path}?filter=${node.dataset.filter}`
  })

  return () => {
    offFilter()
  }
})
