import { component } from 'picoapp'
import gsap from 'gsap'
import { add, remove, on, qs, qsa, rect, toggle } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { toggleMenulist, menuSearch, toggleSearch, menuContent, menuSearchForm, menuSearchQuery } = choozy(node)
  let openMenu = qs('.js-openMenu')

  //TO DO remove listeners
  on(openMenu, 'click', () => {
    add(node, 'is-open')
  })

  qsa('.js-closeMenu').forEach((el) => {
    on(el, 'click', () => {
      remove(node, 'is-open')
    })
  })

  on(toggleSearch, 'click', () => {
    add(menuContent, 'hidden')
    remove(menuSearch, 'hidden')
    remove(toggleMenulist, 'active')
    add(toggleSearch, 'active')
  })

  on(toggleMenulist, 'click', () => {
    remove(menuContent, 'hidden')
    add(menuSearch, 'hidden')
    add(toggleMenulist, 'active')
    remove(toggleSearch, 'active')
  })

  on(menuSearchForm, 'submit', (e) => {
    e.preventDefault()
    window.location = `/search/?query=${menuSearchQuery.value}`
    remove(menu, 'is-open')
  })

  return () => {}
})
