import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa, toggle } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { searchQuery, searchForm, searchClose, searchOpen, searchContainer } =
    choozy(node)

  on(searchForm, 'submit', (e) => {
    e.preventDefault()
    window.location = `/search/?query=${searchQuery.value}`
    add(searchContainer, 'inactive')
  })

  on(searchClose, 'click', () => {
    add(searchContainer, 'inactive')
  })

  on(searchOpen, 'click', () => {
    remove(searchContainer, 'inactive')
  })
})
