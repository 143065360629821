import { component } from 'picoapp'
import choozy from 'choozy'
import { remove, add, qs, on, qsa, has } from 'martha'
import EmblaCarousel from 'embla-carousel'

export default component((node, ctx) => {
  let { sliderToggle, sliderContent, bar, sliderContainer } = choozy(node)

  const slider = on(sliderToggle, 'click', (e) => {
    if (has(e.target, 'active')) return

    let curIndex = e.target.dataset.index
    let sliders = qsa('.js-sliderContent')
    let toggles = qsa('.js-sliderToggle')
    let targetToggle = qs(`.js-sliderToggle[data-index="${curIndex}"]`)

    toggles.forEach((content) => remove(content, 'active'))
    add(targetToggle, 'active')

    sliders.forEach((content) => add(content, 'hidden'))
    remove(qs(`.js-sliderContent[data-index="${curIndex}"]`), 'hidden')

    bar.style.left = `${((curIndex - 1) / sliders.length) * 100}%`

    if (document.documentElement.clientWidth < 850) {
      sliderContainer.scrollTo({
        top: 0,
        left: targetToggle.offsetLeft,
        behavior: 'smooth',
      })
    }
  })

  return () => {
    slider()
  }
})
